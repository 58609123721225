import React from 'react';

function Heading() {
    return (<>
        <h1>
            <span>Welcome</span>
        </h1>

    </>)
}
export default Heading;