import React from 'react';
import Body from './body';
import Heading from './heading';
function Site() {
    return (<>
        <Heading />
        <div className="App">

            <Body />
        </div>
    </>
    )

}

export default Site;